import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Icon from "@mdi/react";
import {
  mdiQrcode,
  mdiQrcodeScan,
  mdiPackageVariantClosedPlus,
  mdiPackageVariantClosedMinus,
  mdiInformationVariant,
} from "@mdi/js";
import { Link } from "react-router-dom";
//import StatsTable from "../components/StatsTable";
import ProductsList from "../components/ProductsList";
import { LookContext } from "../Contexts/LookContext";
import { useAuthenticationContext } from "../Contexts/AuthenticationContext";
import { useProductsContext } from "../Contexts/ProductsContext";
import classes from "./page.module.css";

function Home() {
  //const [dataStats, setDataStats] = useState(null);
  //const [displayStats, setDisplayStats] = useState(false);
  const isMobile = useContext(LookContext);
  const { authenticationStatus } = useAuthenticationContext();
  const { productsList } = useProductsContext();

  //reload component whenever productsList changes
  useEffect(() => {
    console.log("Home.js: useEffect: productsList changed");
  }, [productsList]);

  useEffect(() => {
    console.log("---------------------------------------------");
    console.log(`This is Home page, and isMobile has changed to: ${isMobile}`);
    console.log("---------------------------------------------");
  }, [isMobile]);

  return (
    <div className={classes.pagebody}>
      {/*<h1 className={classes.pageTitle}>Nakhal Dates Inventory</h1>*/}
      <h1 className={classes.pageTitle}>تمور نخل</h1>

      <div className={classes.about_container}>
        <div className={`${classes.about} ${classes._ar}`}>
          نبذة عن المشروع والمنتجات وطريقة التواصل للطلب والاستفسار.
        </div>
        <div className={`${classes.about} ${classes._en}`}>
          About the project, products, and how to order and inquire.
        </div>
      </div>

      <div className={classes.products}>
        {productsList && (
          <ProductsList products={productsList} modify={false} />
        )}
      </div>

      <div className={classes.mainGrid}>
        {/*<div className={classes.mainIcons}>
          <Link to="/qrgenerator">
            <Button variant="contained" size="100%" color="secondary">
              <Icon
                style={{ padding: 10 }}
                path={mdiQrcode}
                title="QR Generator"
                size={"60%"}
                color="white"
              />
            </Button>
          </Link>
        </div>*/}
        {authenticationStatus.isAuthenticated && (
          <>
            <div className={classes.mainIcons}>
              <Link to="/qrscanner">
                <Button variant="contained" size="100%" color="primary">
                  <Icon
                    style={{ padding: 10 }}
                    path={mdiQrcodeScan}
                    title="QR Scanner"
                    size={"80%"}
                    color="white"
                  />
                </Button>
              </Link>
            </div>
            <div className={classes.mainIcons}>
              <Link to="/add">
                <Button variant="contained" size="100%" color="secondary">
                  <Icon
                    style={{ padding: 10 }}
                    path={mdiPackageVariantClosedPlus}
                    title="Add Box"
                    size={"80%"}
                    color="white"
                  />
                </Button>
              </Link>
            </div>
            <div className={classes.mainIcons}>
              <Link to="/remove">
                <Button variant="contained" size="100%" color="primary">
                  <Icon
                    style={{ padding: 10 }}
                    path={mdiPackageVariantClosedMinus}
                    title="Remove Box"
                    size={"80%"}
                    color="white"
                  />
                </Button>
              </Link>
            </div>
            <div className={classes.mainIcons}>
              <Link to="/stats">
                <Button variant="contained" size="100%" color="secondary">
                  <Icon
                    style={{ padding: 10 }}
                    path={mdiInformationVariant}
                    title="Remove Box"
                    size={"80%"}
                    color="white"
                  />
                </Button>
              </Link>
            </div>
          </>
        )}
      </div>
      {/*{displayStats && (
        <div className={classes.infoDiv}>
          <HighLevelStats stats={dataStats} />
        </div>
      )}*/}
    </div>
  );
}

export default Home;

/**
 * return (
    <div className={classes.pagebody}>
      <Typography style={{ margin: 30 }} variant="h2">
        Nakhal Dates Inventory System
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Link to="/qrgenerator">
            <Button variant="contained" size="large" color="secondary">
              <Icon
                style={{ padding: 10 }}
                path={mdiQrcode}
                title="QR Generator"
                size={10}
                color="white"
              />
            </Button>
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Link to="/qrscanner">
            <Button variant="contained" size="large" color="primary">
              <Icon
                style={{ padding: 10 }}
                path={mdiQrcodeScan}
                title="QR Scanner"
                size={10}
                color="white"
              />
            </Button>
          </Link>
        </Grid>
      </Grid>
      {displayStats && (
        <div>
          <HighLevelStats stats={dataStats}/>
          <StatsTable stats={dataStats} />
        </div>
      )}
    </div>
  );
 */
