import React from "react";
//import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import MyButton from "../MyButton";

import modal_classes from "./modal.module.css";

function ModalAlert({ show, alertMessage, handleClose }) {
  //const { t, i18n } = useTranslation(["modal"]);

  const closeAlertModal = () => {
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} /*centered dir={i18n.dir()}*/>
      <Modal.Header closeButton className={modal_classes.modalHeader}>
        <Modal.Title className={modal_classes.modalTitle}>
          Alert
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={modal_classes.modalBody}>
          <div className={modal_classes.modalBody_alert_div}>
            <p>{alertMessage}</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={modal_classes.buttonsRow}>
          <MyButton
            variant={"secondary"}
            onClickHandler={closeAlertModal}
            title={"Close"}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAlert;