const serialURL = "api/serial";
const statsURL = "api/stats";
const addBoxURL = "api/addbox";
const removeBoxURL = "api/removebox";
const enquireBoxURL = "api/enquire";
const addUserURL = "api/user";
const userLoginURL = "api/login";
const userLogoutURL = "/api/logout";
const productURL = "/api/product";
const productsURL = "/api/products";

const fetchSerialNumber_local = async () => {
  //let url = api_uri + serialURL;
  //console.log(`api_uri: ${api_uri}.`);
  //console.log(`serialURL: ${serialURL}.`);
  //console.log(`url: ${url}.`);
  try {
    const res = await fetch(serialURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    console.log(`res.status: ${res.status}.`);
    if (res) {
      console.log("trying to read serial");
      const data = await res.json();
      data.status = res.status;
      return data;
    } else {
      console.log("dont read serial - return null");
      return null;
    }
  } catch (error) {
    console.log(`Error fetching ${serialURL}: ${error}`);
  }
};

const fetchStats = async () => {
  console.log("fetchStats is called by fetchDataStats.");
  //const { user } = useUserfront();
  try {
    const res = await fetch(statsURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    console.log(`res.status: ${res.status}.`);
    if (res) {
      const data = await res.json();
      data.status = res.status;
      return data;
    } else {
      console.log("could not get data stats - return null");
      return null;
    }
  } catch (error) {
    console.log(`Error fetching ${statsURL}: ${error}`);
  }
};

const enquireBoxFromDB = async (box) => {
  //const { user } = useUserfront();
  try {
    const res = await fetch(enquireBoxURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(box),
    });
    console.log(`res.status: ${res.status}.`);
    if (res) {
      console.log("enquire get is sent successfully");
      const data = await res.json();
      data.status = res.status;
      return data;
    }
  } catch (error) {
    console.log(`Unable to enquire box, error: ${error}.`);
    return null;
  }
};

const addBoxToDB = async (box) => {
  try {
    const res = await fetch(addBoxURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${Userfront.tokens.accessToken}`,
      },
      body: JSON.stringify(box),
    });
    console.log(`res.status: ${res.status}.`);
    if (res) {
      console.log("add post is sent successfully");
      const data = await res.json();
      data.status = res.status;
      return data;
    }
  } catch (error) {
    console.log("Unable to add box.");
    return null;
  }
};

const removeBoxFromDB = async (box) => {
  try {
    const res = await fetch(removeBoxURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${Userfront.tokens.accessToken}`,
      },
      body: JSON.stringify(box),
    });
    console.log(`res.status: ${res.status}.`);
    if (res) {
      console.log("remove post is sent successfully");
      const data = await res.json();
      data.status = res.status;
      return data;
    }
  } catch (error) {
    console.log("Unable to remove box.");
    return null;
  }
};

const addUser_local = async (username, hashedKilma, hashedSkilma) => {
  let data = { username: username, kilma: hashedKilma, skilma: hashedSkilma };
  try {
    const res = await fetch(addUserURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    console.log(`res.status: ${res.status}.`);
    if (res) {
      console.log("add user is sent successfully");
      const data = await res.json();
      data.status = res.status;
      return data;
    }
  } catch (error) {
    console.log("Unable to add user.");
    return null;
  }
};

const userLogin_local = async (username, kilma) => {
  let data = { username: username, kilma: kilma };
  try {
    const res = await fetch(userLoginURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    console.log(`res.status: ${res.status}.`);
    if (res.ok) {
      console.log("login is sent successfully");
      const data = await res.json();
      return data;
    }
  } catch (error) {
    console.log("Unable to login.");
    return null;
  }
};

const userLogout_local = async () => {
  try {
    const res = await fetch(userLogoutURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    console.log(`res.status: ${res.status}.`);
    if (res) {
      console.log("logout is sent successfully");
      return;
    }
  } catch (error) {
    console.log("Unable to logout.");
    return null;
  }
};

const addProduct_local = async (product) => {
  let data = { product: product };
  try {
    const res = await fetch(productURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    console.log(`res.status: ${res.status}.`);
    if (res.ok) {
      console.log("add-product request is successfully handled by server.");
      const data = await res.json();
      return data;
    }
  } catch (error) {
    console.log("Unable to process add-product in client, return null.");
    return null;
  }
};

const fetchProducts_local = async () => {
  try {
    const res = await fetch(productsURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    console.log(`res.status: ${res.status}.`);
    if (res.ok) {
      console.log("fetch-products request is successfully handled by server.");
      const data = await res.json();
      return data;
    }
  } catch (error) {
    console.log("Unable to process fetch-products in client, return null.");
    return null;
  }
};

const deleteProduct_local = async (id, name) => {
  let data = { id: id, name: name };

  try {
    const res = await fetch(productURL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    console.log(`res.status: ${res.status}.`);
    if (res.ok) {
      console.log("delete-product request is successfully handled by server.");
      const data = await res.json();
      return data;
    }
  } catch (error) {
    console.log("Unable to process delete-product in client, return null.");
    return null;
  }
};

/********************************************************************************
 * *****************************************************************************
 * *****************************************************************************
 * *****************************************************************************
 * *****************************************************************************
 * ******************************************************************************
 */

export const userLoginAPI = (username, kilma, setLoginResponse) => {
  console.log(`This is userLoginAPI, username: ${username}.`);
  userLogin_local(username, kilma).then((data) => {
    if (data) {
      console.log(
        `This is userLoginAPI, response data is: ${JSON.stringify(data)}.`
      );
      setLoginResponse(data);
    } else {
      setLoginResponse({
        success: false,
        username: username,
        errorMessage: "Unable to login.",
      });
    }
  });
};

export const userLogoutAPI = async () => {
  console.log("This is userLogoutAPI.");
  await userLogout_local();
};

export const addUserAPI = (
  username,
  hashedKilma,
  hashedSkilma,
  setAddUserResponse
) => {
  console.log(`This is addUserAPI, username: ${username}.`);
  addUser_local(username, hashedKilma, hashedSkilma).then((data) => {
    if (data) {
      console.log(
        `This is addUserAPI, response data is: ${JSON.stringify(data)}.`
      );
      setAddUserResponse(data);
    } else {
      setAddUserResponse({
        success: false,
        username: username,
        errorMessage: "Unable to add user.",
      });
    }
  });
};

export const readSerialNumber = (setGetSerialNumberResponse) => {
  console.log("This is readSerialNumber");
  fetchSerialNumber_local().then((data) => {
    if (data) {
      console.log(
        `data received in readSerialNumber: ${JSON.stringify(data)}.`
      );
      setGetSerialNumberResponse(data);
    } else {
      setGetSerialNumberResponse({
        success: false,
        errorMessage: "Unable to read serial number.",
      });
    }
  });
};

export const enquireBox = (box, setEnquiryResponse) => {
  console.log(
    `This is enquireBox api function, box is: ${JSON.stringify(box)}`
  );
  enquireBoxFromDB(box).then((data) => {
    if (data) {
      console.log(
        `This is enquireBox api, response data is: ${JSON.stringify(data)}.`
      );
      setEnquiryResponse(data);
    } else {
      setEnquiryResponse(null);
    }
  });
};

export const addBox = (box, setNewAddBoxResponse) => {
  console.log(`This is addBox api function, box is: ${JSON.stringify(box)}`);
  addBoxToDB(box).then((data) => {
    if (data) {
      console.log(
        `This is addBox api, response data is: ${JSON.stringify(data)}.`
      );
      setNewAddBoxResponse(data);
    } else {
      setNewAddBoxResponse(null);
    }
  });
};

export const removeBox = (box, setNewRemoveBoxResponse) => {
  console.log(`This is removeBox api function, box is: ${JSON.stringify(box)}`);
  removeBoxFromDB(box).then((data) => {
    if (data) {
      console.log(
        `This is removeBox api, response data is: ${JSON.stringify(data)}.`
      );
      setNewRemoveBoxResponse(data);
    } else {
      setNewRemoveBoxResponse(null);
    }
  });
};

export const fetchDataStats = (setDataStats) => {
  console.log("This is fetchDataStats");
  fetchStats().then((data) => {
    if (data) {
      console.log(JSON.stringify(data));
    }

    if (data === null || typeof data === "undefined") {
      console.log("Fetched stats data is undefinied.");
      setDataStats(null);
    } else {
      setDataStats(data);
    }
  });
};

export const addProduct = (product, setAddProductResponse) => {
  console.log(`This is addProduct api function`);
  addProduct_local(product).then((data) => {
    if (data) {
      console.log(
        `This is addProduct api, response data is: ${JSON.stringify(data)}.`
      );
      setAddProductResponse(data);
    } else {
      setAddProductResponse({
        sucess: false,
        errorMessage: "Unable to get response from server.",
      });
    }
  });
};

export const fetchProducts = (setFetchProductsResponse) => {
  console.log("This is fetchProducts");
  fetchProducts_local().then((data) => {
    if (data) {
      console.log(`data is received after fetchProducts...`);
      setFetchProductsResponse(data);
    } else {
      setFetchProductsResponse({
        success: false,
        errorMessage: "Unable to fetch products.",
      });
    }
  });
};

export const deleteProduct = (id, name, setDeleteProductResponse) => {
  console.log(`This is deleteProduct api function`);
  deleteProduct_local(id, name).then((data) => {
    if (data) {
      console.log(
        `This is deleteProduct api, response data is: ${JSON.stringify(data)}.`
      );
      setDeleteProductResponse(data);
    } else {
      setDeleteProductResponse({
        sucess: false,
        errorMessage: "Unable to get response from server.",
      });
    }
  });
};
