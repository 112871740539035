import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import SelectLabels from "../components/SelectLabels";
import QRList from "../components/QRList";
import MySpinner from "../components/MySpinner";
import ModalAlert from "../components/modals/ModalAlert";
import { useAuthenticationContext } from "../Contexts/AuthenticationContext";

import { readSerialNumber } from "../api/api";

import classes from "./qrgenerator.module.css";
import pageClasses from "./page.module.css";
//import { width } from "@mui/system";

function QRgenerator() {
  const { resetAuthentication } = useAuthenticationContext();
  //const [qr, setQr] = useState("hello");
  const prefix = "ND";
  const [serialNumber, setSerialNumber] = useState("");
  const [getSerialNumberResponse, setGetSerialNumberResponse] = useState(null);
  const [date, setDate] = useState("");
  const [boxWeight, setBoxWeight] = useState("");
  const [numOfBags, setNumOfBags] = useState("");
  const [bagWeight, setBagWeight] = useState("");

  const [datesType, setDatesType] = useState("");
  const [datesGrade, setDatesGrade] = useState("");
  const [extraLabel, setExtraLabel] = useState("");
  const [datesWash, setDatesWash] = useState("");

  //const [qrString, setQrString] = useState("");
  const [displayQRs, setDisplayQRs] = useState(false);
  const [qrStrings, setQrStrings] = useState([]);
  const [baseQRString, setBaseQRString] = useState("");

  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState("An Error!");

  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    if (getSerialNumberResponse) {
      console.log(
        `getSerialNumberResponse: ${JSON.stringify(getSerialNumberResponse)}.`
      );
      if (getSerialNumberResponse.success) {
        setSerialNumber(getSerialNumberResponse.serial);
      } else {
        console.log(
          `Error reading serial number: ${
            getSerialNumberResponse.errorMessage || "undefined"
          }.`
        );
        if (getSerialNumberResponse.status === 400) {
          console.log(`Status is 400, reset authentication status.`);
          resetAuthentication();
        }
        setModalAlertMessage("Unable to get serial number.");
        setShowModalAlert(true);
      }
      setGetSerialNumberResponse(null);
      setIsBusy(false);
    }
  }, [getSerialNumberResponse]);

  useEffect(() => {
    if (
      date &&
      boxWeight &&
      numOfBags &&
      bagWeight &&
      datesType &&
      datesGrade &&
      extraLabel &&
      datesWash
    ) {
      setBaseQRString(
        date +
          "-" +
          boxWeight +
          "-" +
          numOfBags +
          "-" +
          bagWeight +
          "-" +
          datesGrade +
          "-(" +
          datesWash +
          ")-" +
          extraLabel +
          "-" +
          datesType
      );
    }
  }, [
    date,
    boxWeight,
    numOfBags,
    bagWeight,
    datesType,
    datesGrade,
    extraLabel,
    datesWash,
  ]);

  useEffect(() => {
    let data = [];
    const num_of_qrs = 20;
    if (serialNumber && baseQRString) {
      for (let i = 0; i < num_of_qrs; i++)
        data[i] = {
          id: parseInt(serialNumber) + i,
          qrString:
            prefix +
            "-" +
            (parseInt(serialNumber) + i).toString() +
            "-" +
            baseQRString,
        };

      console.log(`The generated QR strings: ${JSON.stringify(data)}.`);
      setQrStrings(data);
    }
  }, [prefix, serialNumber, baseQRString]);

  useEffect(() => {
    console.log(`New QRs have been generated: ${JSON.stringify(qrStrings)}.`);
  }, [qrStrings]);

  /*   const loadData = async () => {
    console.log("loadData is called by useEffect.");
    const url = "/serial";
    try {
      const res = await fetch(url);
      console.log(`res.status: ${res.status}.`);
      if (res.ok) {
        const data = await res.json();
        return data;
      }
    } catch (error) {
      console.log(`Error fetching ${url}: ${error}`);
    }
  }; */

  const minimum_width = 1000;

  const [widthOK, setWidthOK] = useState(window.innerWidth >= minimum_width);

  function handleResize() {
    setWidthOK(window.innerWidth >= minimum_width);
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    console.log(`widthOK is: ${widthOK}`);
  }, [widthOK]);

  useEffect(() => {
    readSerialNumber(setGetSerialNumberResponse);
    setIsBusy(true);

    //set date
    const d = new Date();
    const yr = d.getFullYear().toString().slice(-2);
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    console.log(`yr: ${yr}, month: ${month}`);
    setDate(month + "/" + yr);
  }, []);

  /*useEffect(() => {
    console.log(`Serial has changed. The new serial is:${serialNumber}.`);
    //this should be a 'base serial number' fetched from DB.
  }, [serialNumber]);*/

  /*useEffect(() => {
    setQrString(
      prefix +
        "-" +
        serialNumber +
        "-" +
        date +
        "-" +
        boxWeight +
        "-" +
        numOfBags +
        "-" +
        bagWeight
    );
  }, [date, serialNumber, boxWeight, numOfBags, bagWeight]);*/

  //const handleChange = (event) => {
  //  setQr(event.target.value);
  //};

  const weightOptions = [
    { value: "0.5", label: "0.5 Kg" },
    { value: "1", label: "1 Kg" },
    { value: "5", label: "5 Kg" },
    { value: "10", label: "10 Kg" },
    { value: "20", label: "20 Kg" },
  ];

  const numOfBagsOptions = [
    { value: "1", label: "1 Bag" },
    { value: "2", label: "2 Bags" },
    { value: "5", label: "5 Bags" },
    { value: "10", label: "10 Bags" },
    { value: "20", label: "20 Bags" },
  ];

  const datesTypeOptions = [
    { value: "khalas", label: "خلاص" },
    { value: "Handhal", label: "حنضل" },
    { value: "Nabhani", label: "نبهاني" },
    { value: "Lazad", label: "لزاد" },
    { value: "Jabri", label: "جبري" },
    { value: "BoMaan", label: "بومعان" },
    { value: "N.Hilali", label: "نغال هلالي" },
    { value: "Naghal", label: "نغال" },
    { value: "Hilali", label: "هلالي" },
    { value: "Zadah", label: "زاده" },
    { value: "Khisab", label: "خصاب" },
    { value: "Lazad", label: "لزاد" },
    { value: "Narenja", label: "نارنجه" },
    { value: "Fardh", label: "فرض" },
    { value: "H.Alhasa", label: "الحسا" },
    { value: "Q.Basra", label: "بصرى" },
    { value: "Barni", label: "برني" },
    { value: "Khenezi", label: "خنيزي" },
    { value: "Ajwa", label: "عجوة" },
    { value: "Madlooki", label: "مدلوكي" },
    { value: "Q.Enin", label: "عنين" },
    { value: "Narenja", label: "نارنجه" },
    { value: "Q.Biaq", label: "بياق" },
    { value: "B.Adi", label: "بنت عدي" },
  ];

  const datesGradeOptions = [
    { value: "1", label: "1 Star" },
    { value: "2", label: "2 Stars" },
    { value: "3", label: "3 Stars" },
    { value: "4", label: "4 Stars" },
    { value: "5", label: "5 Stars" },
  ];

  const extraLabelOptions = [
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "C", label: "C" },
    { value: "D", label: "D" },
    { value: "E", label: "E" },
    { value: "F", label: "F" },
    { value: "G", label: "G" },
    { value: "H", label: "H" },
    { value: "I", label: "I" },
    { value: "J", label: "J" },
    { value: "K", label: "K" },
    { value: "L", label: "L" },
    { value: "M", label: "M" },
    { value: "N", label: "N" },
    { value: "O", label: "O" },
    { value: "P", label: "P" },
    { value: "Q", label: "Q" },
    { value: "R", label: "R" },
    { value: "S", label: "S" },
    { value: "T", label: "T" },
    { value: "U", label: "U" },
    { value: "V", label: "V" },
    { value: "W", label: "W" },
    { value: "X", label: "X" },
    { value: "Y", label: "Y" },
    { value: "Z", label: "Z" },
  ];

  const datesWashOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
  ];

  const dropDownWeightHandler = (selectedWeightValue) => {
    console.log(`Selected weight value: ${selectedWeightValue}`);
    setBoxWeight(selectedWeightValue);
  };

  const dropDownNumOfBagstHandler = (selectedNumOfBags) => {
    console.log(`Selected number of bags: ${selectedNumOfBags}`);
    setNumOfBags(selectedNumOfBags);
  };

  const dropDownBagWeightHandler = (selectedBagWeightValue) => {
    console.log(`Selected bag weight value: ${selectedBagWeightValue}`);
    setBagWeight(selectedBagWeightValue);
  };

  const dropDownDatesTypeHandler = (selectedDatesTypeValue) => {
    console.log(`Selected dates type: ${selectedDatesTypeValue}`);
    setDatesType(selectedDatesTypeValue);
  };

  const dropDownDatesGradeHandler = (selectedDatesGradeValue) => {
    console.log(`Selected dates grade: ${selectedDatesGradeValue}`);
    setDatesGrade(selectedDatesGradeValue);
  };

  const dropDownLabelHandler = (selectedLabelValue) => {
    console.log(`Selected label: ${selectedLabelValue}`);
    setExtraLabel(selectedLabelValue);
  };

  const dropDownDatesWashHandler = (selectedDatesWashValue) => {
    console.log(`Selected dates grade: ${selectedDatesWashValue}`);
    setDatesWash(selectedDatesWashValue);
  };

  const toggleDisplayQRs = () => {
    console.log(`Toggling displayQRs, current displayQRs: ${displayQRs}`);
    setDisplayQRs((prevState) => {
      return !prevState;
    });
    //console.log(`displayQRs: ${displayQRs}`);
  };

  return (
    <div className={pageClasses.pagebody}>
      {!widthOK && (
        <div>لا يمكن عرض هذه الصفحة في أقل من ({`${minimum_width}`}) بيكسل</div>
      )}
      {widthOK && (
        <>
          <h1 className={pageClasses.pageTitle}>تكوين الملصقات</h1>

          <div style={{ marginTop: 30 }} className={classes.inputDiv}>
            <div
              style={{ marginTop: 30 }}
              className={classes.inputDiv_horizontalElement}
            >
              <TextField
                className={classes.textfield}
                style={{ width: 60 }}
                value={prefix}
                label="Prefix"
                size="large"
                variant="outlined"
                color="primary"
                inputProps={{ readOnly: true }}
              />
              <TextField
                className={classes.textfield}
                style={{ width: 120 }}
                value={serialNumber}
                label="Serial number"
                size="large"
                variant="outlined"
                color="primary"
                inputProps={{ readOnly: true }}
              />
              <TextField
                className={classes.textfield}
                style={{ width: 120 }}
                value={date}
                label="Year and month"
                size="large"
                variant="outlined"
                color="primary"
                inputProps={{ readOnly: true }}
              />
            </div>
            <div
              style={{ marginTop: 30 }}
              className={classes.inputDiv_horizontalElement}
            >
              <SelectLabels
                className={classes.selectLabels}
                options={weightOptions}
                givenLabel={"وزن الكرتون"}
                listLabel={"Choose box weight"}
                handler={dropDownWeightHandler}
              />
              <SelectLabels
                options={numOfBagsOptions}
                givenLabel={"عدد الأكياس"}
                listLabel={"Choose num of bags"}
                handler={dropDownNumOfBagstHandler}
              />
              <SelectLabels
                options={weightOptions}
                givenLabel={"وزن الكيس"}
                listLabel={"Choose bag weight"}
                handler={dropDownBagWeightHandler}
              />
            </div>

            <div
              style={{ marginTop: 30 }}
              className={classes.inputDiv_horizontalElement}
            >
              <SelectLabels
                options={datesTypeOptions}
                givenLabel={"نوع التمر"}
                listLabel={"اختر نوع التمر"}
                handler={dropDownDatesTypeHandler}
              />
              <SelectLabels
                options={datesGradeOptions}
                givenLabel={"جودة التمر"}
                listLabel={"اختر جودة التمر"}
                handler={dropDownDatesGradeHandler}
              />
            </div>
            <div
              style={{ marginTop: 30 }}
              className={classes.inputDiv_horizontalElement}
            >
              <SelectLabels
                options={extraLabelOptions}
                givenLabel={"علامة إضافية"}
                listLabel={"اختر علامة إضافية"}
                handler={dropDownLabelHandler}
              />
              <SelectLabels
                options={datesWashOptions}
                givenLabel={"غسلة التمر"}
                listLabel={"اختر غسلة التمر"}
                handler={dropDownDatesWashHandler}
              />
            </div>
          </div>

          <button className={pageClasses.button} onClick={toggleDisplayQRs}>
            {displayQRs ? "إخفاء الملصقات" : "عرض الملصقات"}
          </button>

          {displayQRs && qrStrings.length > 0 && (
            <QRList qrStrings={qrStrings} />
          )}
        </>
      )}

      <ModalAlert
        show={showModalAlert}
        alertMessage={modalAlertMessage}
        handleClose={() => {
          setShowModalAlert(false);
        }}
      />

      {isBusy && <MySpinner />}
    </div>
  );
}

export default QRgenerator;
