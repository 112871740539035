import React, { useEffect, useState, useContext } from "react";
import { Tabs, Tab, Box } from "@mui/material";

import pageClasses from "./page.module.css";
import { LookContext } from "../Contexts/LookContext";
import { useProductsContext } from "../Contexts/ProductsContext";
import AddProduct from "../components/AddProduct";
import ProductsList from "../components/ProductsList";
import MySpinner from "../components/MySpinner";
import ModalAlert from "../components/modals/ModalAlert";
import ModalInfo from "../components/modals/ModalInfo";
import { deleteProduct } from "../api/api";

function ProductsManager() {
  const isMobile = useContext(LookContext);
  const { productsList, reFetchProducts } = useProductsContext();
  const [value, setValue] = useState("view_products");
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState("");
  const [modalInfoMessage, setModalInfoMessage] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [deleteProductResponse, setDeleteProductResponse] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //reload component whenever productsList changes
  useEffect(() => {
    console.log("ProductsManager: productsList has changed.");
  }, [productsList]);

  useEffect(() => {
    if (deleteProductResponse) {
      setIsBusy(false);
      if (deleteProductResponse.success) {
        setModalInfoMessage(
          `Product ${deleteProductResponse.name} is deleted successfully.`
        );
        setShowModalInfo(true);
        reFetchProducts();
      } else {
        setModalAlertMessage(
          `Unable to delete product. Error message: ${
            deleteProductResponse.errorMessage || "undefined."
          }`
        );
        setShowModalAlert(true);
      }
    }
  }, [deleteProductResponse]);

  const deleteHandler = (id, name) => {
    console.log(`Attempting to delete product, id: ${id}, name: ${name}.`);
    setDeleteProductResponse(null);
    setIsBusy(true);
    deleteProduct(id, name, setDeleteProductResponse);
  };

  const closeModalAlert = () => {
    setShowModalAlert(false);
  };
  const closeModalInfo = () => {
    setShowModalInfo(false);
  };

  return (
    <div className={pageClasses.pagebody}>
      <h1 className={pageClasses.pageTitle}>إدارة المنتجات</h1>
      <div className={pageClasses.products_management_container}>
        <Box
          className={pageClasses.tabs_row}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="products tabs"
            className="mb-3"
          >
            <Tab value="add_product" label="Add Product" />
            <Tab value="view_products" label="View Products" />
          </Tabs>
        </Box>

        {value === "add_product" && <AddProduct />}
        {value === "view_products" && (
          <ProductsList
            products={productsList}
            modify={true}
            deleteHandler={deleteHandler}
          />
        )}
      </div>
      {showModalAlert && (
        <ModalAlert
          show={showModalAlert}
          alertMessage={modalAlertMessage}
          handleClose={closeModalAlert}
        />
      )}
      {showModalInfo && (
        <ModalInfo
          show={showModalInfo}
          infoMessage={modalInfoMessage}
          handleClose={closeModalInfo}
        />
      )}
      {isBusy && <MySpinner />}
    </div>
  );
}

export default ProductsManager;
