import React from "react";
import { QrReader } from "react-qr-reader";
import classes from "./qrscanner.module.css";

function QRscanner({ scanHandler, errorHandler }) {
  //const [result, setResult] = useState("no result");

  const handleScan = (result, error) => {
    if (!!result) {
      //setResult(result?.text);
      scanHandler(result?.text);
      console.log(result);
    }

    if (!!error) {
      console.info(error);
      errorHandler(error);
    }
  };

  return (
    <div className={classes.qrscannerdiv}>
      <QrReader
        key="environment"
        constraints={{ facingMode: "environment" }}
        onResult={handleScan}
        style={{ width: "40%", height: "30%" }}
      />
    </div>
  );
}

export default QRscanner;

/*
return (
    <div className={classes.qrscannerdiv}>
      <QrReader
        onResult={handleScan}
        style={{ width: "40%", height: "30%" }}
      />
      <p>{result}</p>
    </div>
  ); */
