import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";
import Login from "./pages/Login";
//import Signup from "./pages/Signup";
import Home from "./pages/Home";
import AddBox from "./pages/AddBox";
import RemoveBox from "./pages/RemoveBox";
import QRgenerator from "./pages/QRgenerator";
import QRscannerpage from "./pages/Qrscannerpage";
import Stats from "./pages/Stats";
import ProductsManager from "./pages/ProductsManager";
import UserRegisteration from "./pages/UserRegisteration";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { LookContext } from "./Contexts/LookContext";
import { useAuthenticationContext } from "./Contexts/AuthenticationContext";

function RequireAuth({ children }) {
  const { authenticationStatus } = useAuthenticationContext();
  let location = useLocation();
  if (
    authenticationStatus.isAuthenticated &&
    (authenticationStatus.role === "user" ||
      authenticationStatus.role === "admin")
  ) {
    console.log(`You're already logged in as a ${authenticationStatus.role}.`);

    return children;
  } else {
    console.log("you must login first.");
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
}

function RequireAdminAuth({ children }) {
  const { authenticationStatus } = useAuthenticationContext();
  let location = useLocation();
  if (
    authenticationStatus.isAuthenticated &&
    authenticationStatus.role === "admin"
  ) {
    console.log(`You're already logged in as a ${authenticationStatus.role}.`);

    return children;
  } else {
    console.log("you must login first.");
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
}

function App() {
  const [windowDimension, setWindowDimension] = useState(window.innerWidth);

  function handleResize() {
    setWindowDimension(window.innerWidth);
  }

  // This effect runs once, after the first render
  useEffect(() => {
    document.title = "تمور نخل";
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension && windowDimension <= 640;

  useEffect(() => {
    console.log(
      "*************************************************************"
    );
    console.log(`isMobile: ${isMobile}.`);
    console.log(
      "*************************************************************"
    );
  }, [isMobile]);

  return (
    <div className="App">
      <Router>
        <LookContext.Provider value={isMobile}>
          <NavBar />

          <Routes>
            <Route path="/login" element={<Login />} />
            {/*<Route path="/signup" element={<Signup />} />*/}

            <Route
              path="/qrgenerator"
              element={
                <RequireAuth>
                  <QRgenerator />
                </RequireAuth>
              }
            />
            <Route
              path="/add"
              element={
                <RequireAuth>
                  <AddBox />
                </RequireAuth>
              }
            />
            <Route
              path="/remove"
              element={
                <RequireAuth>
                  <RemoveBox />
                </RequireAuth>
              }
            />
            <Route
              path="/qrscanner"
              element={
                <RequireAuth>
                  <QRscannerpage />
                </RequireAuth>
              }
            />
            <Route
              path="/stats"
              element={
                <RequireAuth>
                  <Stats />
                </RequireAuth>
              }
            />

            <Route
              path="/products"
              element={
                <RequireAuth>
                  <ProductsManager />
                </RequireAuth>
              }
            />

            <Route
              path="/registeration"
              element={
                <RequireAdminAuth>
                  <UserRegisteration />
                </RequireAdminAuth>
              }
            />

            <Route path="/" element={<Home />} />
          </Routes>
          <Footer />
        </LookContext.Provider>
      </Router>
    </div>
  );
}

export default App;
