const validPrefix = "ND";
const minSerial = 10000;
const maxSerial = 99999;
const possibleWeights = [0.5, 1, 5, 10, 20];
const possibleNumOfBags = [1, 2, 5, 10, 20];

export const parseQRtoElements = (qrstring) => {
  console.log(
    `This is parseQRtoElements, and received qrstring is: ${qrstring}.`
  );
  const elementArray = qrstring.split("-");
  console.log(`elementArray length: ${elementArray.length}.`);
  elementArray.forEach((element) => {
    console.log(element);
  });

  let box = {};
  //check prefix
  if (elementArray[0] == validPrefix) {
    console.log(`elementArray[0] is equal to prefix: ${elementArray[0]}`);
    box = { ...box, prefix: elementArray[0] };
    console.log(`updated box: ${JSON.stringify(box)}`);
  } else {
    console.log(`invalid prefix: ${elementArray[0]}.`);
    return null;
  }

  const serialInteger = parseInt(elementArray[1]);
  if (serialInteger >= minSerial && serialInteger <= maxSerial) {
    box = { ...box, serialNumber: serialInteger };
    console.log(`updated box: ${JSON.stringify(box)}`);
  } else {
    console.log(`invalid serial number: ${elementArray[1]}.`);
    return null;
  }

  const month = parseInt(elementArray[2].slice(0, 2));
  const yr = parseInt(elementArray[2].slice(3, 5));
  console.log(`yr: ${yr} and month: ${month}.`);
  if (yr <= 30 && yr >= 22 && month >= 1 && month <= 12) {
    box = { ...box, date: elementArray[2] };
  } else {
    console.log(`invalid date: ${elementArray[2]}.`);
    return null;
  }

  const boxWeightInteger = parseFloat(elementArray[3]);
  const numOfBagsInteger = parseInt(elementArray[4]);
  const bagWeightInteger = parseFloat(elementArray[5]);

  console.log(`Box weight: ${boxWeightInteger}, numofbags: ${numOfBagsInteger},
  bag weight: ${bagWeightInteger}.`);

  console.log(`elementArray[8]: ${elementArray[8]}.`);
  //check that box weight = num of bags * bag weight.
  //also check that weights are valid and num of bags is valid
  if (
    boxWeightInteger === numOfBagsInteger * bagWeightInteger &&
    possibleWeights.includes(bagWeightInteger) &&
    possibleWeights.includes(boxWeightInteger) &&
    possibleNumOfBags.includes(numOfBagsInteger)
  ) {
    box = {
      ...box,
      boxWeight: boxWeightInteger,
      numOfBags: numOfBagsInteger,
      bagWeight: bagWeightInteger,
      datesGrade: parseInt(elementArray[6]),
      datesWash: parseInt(elementArray[7].replace(/[^\d]/g, '')),
      extraLabel: elementArray[8],
      datesType: elementArray[9]
    };
  } else {
    return null;
  }

  console.log(`Valid box with following parameters: ${JSON.stringify(box)}.`);
  return box;
};
