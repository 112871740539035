import React, { useEffect } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import productCardClasses from "./productCard.module.css";
import "../index.css";

function ProductCard({
  id,
  image,
  enName,
  enDescription,
  arName,
  arDescription,
  modify,
  deleteHandler,
}) {
  return (
    <div className={productCardClasses.productCard_container}>
      <div className={productCardClasses.productCard_card}>
        <img
          className={productCardClasses.productCard_image}
          src={image}
          alt={enName}
        />
        <div className={productCardClasses.productCard_text}>
          <div className={productCardClasses.productCard_text_ar}>
            <h3>{arName}</h3>
            <p>{arDescription}</p>
          </div>
          <div className={productCardClasses.productCard_text_en}>
            <h3>{enName}</h3>
            <p>{enDescription}</p>
          </div>
        </div>
        {modify && (
          <div className={productCardClasses.productCard_modify}>
            <DeleteForeverIcon
              className="icon"
              onClick={() => deleteHandler(id, enName)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductCard;
