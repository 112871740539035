import React from "react";
//import StyledBox from "./StyledBox";
import classes from "./HighLevelStats.module.css";

const HighLevelStats = ({ stats }) => {
  return (
    <div className={classes.hls}>
      <div className={classes.infoDiv}>
        <span>
        إجمالي عدد الصناديق: {stats.numOfBoxes}
        </span>
      </div>
      <div  className={classes.infoDiv}>
        <span>
           الوزن الإجمالي (كيلو جرام): {stats.totalWeight}
        </span>
      </div>
      <div  className={classes.infoDiv}>
        <span>
         عدد صناديق 0.5 كيلو: {stats.numOfHalfKBoxes} 
        </span>
      </div>
      <div  className={classes.infoDiv}>
        <span>
         عدد صناديق 1 كيلو: {stats.numOfOneKBoxes} 
        </span>
      </div>
      <div  className={classes.infoDiv}>
        <span>
         عدد صناديق 5 كيلو: {stats.numOfFiveKBoxes} 
        </span>
      </div>
      <div  className={classes.infoDiv}>
        <span>
         عدد صناديق 10 كيلو: {stats.numOfTenKBoxes} 
        </span>
      </div>
      <div  className={classes.infoDiv}>
        <span>
         عدد صناديق 20 كيلو: {stats.numOfTwentyKBoxes} 
        </span>
      </div>
    </div>
  );
};

export default HighLevelStats;
