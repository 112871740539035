import React, { useEffect } from "react";
import ProductCard from "./ProductCard";

function ProductsList({ products, modify, deleteHandler }) {

  return (
    products &&
    products.map((product) => (
      <ProductCard
        key={product._id}
        id={product._id}
        image={product.image}
        enName={product.name}
        enDescription={product.description}
        arName={product.ar_name}
        arDescription={product.ar_description}
        modify={modify}
        deleteHandler={deleteHandler}
      />
    ))
  );
}

export default ProductsList;
