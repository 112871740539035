import CryptoJS from "crypto-js";
const dayjs = require("dayjs");

/**
 * 
 * given an array of {label, value} objects, 
 * this function returns the value corresponding to the given label.
 */
export const getValueFromLabel = (array, label) => {
  const item = array.find((element) => element.label === label);
  return item ? item.value : null;
}

/**
 * given an array of {label, value} objects, 
 * this function returns the label corresponding to the given value.
 */
export const getLabelFromValue = (array, value) => {
  const item = array.find((element) => element.value === value);
  return item ? item.label : null;
};


export const hashPassword = (password) => {
  const hash = CryptoJS.SHA256(password);
  return hash.toString(CryptoJS.enc.Hex);
};

export const checkEnMessageValidity = (text) => {
  if (text.length > 200) {
    return false;
  }
  var regex = /^(?!.*[$%@#*|\/?~])[A-Za-z0-9.,()-\s\n]*$/;
  //check to see if the entered text is valid English text.
  return regex.test(text);
};

export const checkArMessageValidity = (text) => {
  if (text.length > 200) {
    return false;
  }
  const arabicRegex = /^[\u0621-\u063A\u0641-\u064A0-9.,()-\s\n]+$/;

  //check to see if the entered text is valid Arabic text.
  return arabicRegex.test(text);
};

export const checkMessageValidity = (text) => {
  if (text.length > 200) {
    return false;
  }
  var regex = /^[A-Za-z0-9.,()-\s\n]*$/;
  const arabicRegex = /^[\u0621-\u063A\u0641-\u064A0-9.,()-\s\n]+$/;

  //check to see if the entered text is valid English or Arabic text.
  return regex.test(text) || arabicRegex.test(text);
};

export const checkNumberValidity = (text) => {
  // Remove leading/trailing spaces and check if it's a valid number
  const trimmedText = text.trim();
  return !isNaN(trimmedText);
};

export const checkPositiveNumberValidity = (text) => {
  // Remove leading/trailing spaces and check if it's a valid number
  const trimmedText = text.trim();
  return !(isNaN(trimmedText) || trimmedText < 0);
};

export const checkIntegerNumberValidity = (text) => {
  // Use a regular expression to check for a positive integer
  const positiveIntegerPattern = /^[1-9]\d*$/;
  return positiveIntegerPattern.test(text.trim());
};

/*export function checkArNameValidity(text) {
  var regex =
    /^(?!.*[%٪])(?![\u0660-\u0669\u0966-\u096F])[\u0600-\u06FF\s\-.,&'()]*$/;
  return regex.test(text);
}*/

export function checkArNameValidity(text) {
  var regex =
    /^(?!.*[%٪])(?![\u0660-\u0669\u0966-\u096F])(?![^A-Za-z\u0600-\u06FF])[\u0600-\u06FF\s\-.,&'()]*$/;
  return regex.test(text);
}

export function checkEnNameValidity(text) {
  var regex = /^[A-Za-z][A-Za-z0-9\s\-.,&'()]*$/;
  return regex.test(text);
}

export const checkNameValidity = (text) => {
  var regex = /^[A-Za-z][A-Za-z0-9\s\-.,&'()]*$/;
  const arabicRegex = /^[\u0600-\u06FF\s]+$/;

  //check to see if the entered text is valid english or arabic text.
  return regex.test(text) || arabicRegex.test(text);
};

export const checkEmailValidity = (email) => {
  if (email.length > 50) {
    return false;
  }
  const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailPattern.test(email);
};

export const checkGoogleMapUrlValidity = (url) => {
  const googleMapUrlPattern = /^https:\/\/maps\.app\.goo\.gl\/[A-Za-z0-9]+$/;
  return googleMapUrlPattern.test(url);
};

/*export const checkInstagramUrlValidity = (url) => {
  const instagramUrlPattern =
    /^https:\/\/www\.instagram\.com\/[A-Za-z0-9_]+\/?$/;
  return instagramUrlPattern.test(url);
};*/

/*
export const checkInstagramUrlValidity = (url) => {
  //console.log(`This is checkInstagramUrlValidity, url is ${url}`);
  const instagramUrlPattern =
    /^https:\/\/www\.instagram\.com\/[A-Za-z0-9_]+(\/\?[\w=&]*)?$/;
  //const valid = instagramUrlPattern.test(url);
  //console.log(`valid: ${valid}`);
  return instagramUrlPattern.test(url);
};
*/

export const checkInstagramUrlValidity = (url) => {
  const instagramUrlPattern =
    /^https:\/\/www\.instagram\.com\/[A-Za-z0-9_.]+(\/\?[\w=&]*)?$/;
  return instagramUrlPattern.test(url);
};

export const extractTimeFromDateObject = (dayjsObject) => {
  const result = { valid: false, hours: 0, minutes: 0 };

  if (!dayjsObject.isValid()) {
    console.log("Invalid Day.js object");
    return result;
  }

  console.log("dayjsObject is a valid dayjs object");

  // Extract time components
  const hours = dayjsObject.hour();
  const minutes = dayjsObject.minute();

  console.log(`hours: ${hours}, minutes: ${minutes}`);

  // Check if the time components are valid
  if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
    result.valid = true;
    result.hours = hours;
    result.minutes = minutes;
    return result;
  } else {
    result.valid = false;
    return result;
  }
};

export function convertToIndianNumerals(number) {
  const arabicToIndian = {
    0: "\u0660",
    1: "\u0661",
    2: "\u0662",
    3: "\u0663",
    4: "\u0664",
    5: "\u0665",
    6: "\u0666",
    7: "\u0667",
    8: "\u0668",
    9: "\u0669",
  };

  return number
    .toString()
    .split("")
    .map((char) => arabicToIndian[char] || char)
    .join("");
}

export const convertToArabicNumerals = (number) => {
  const indianToArabic = {
    "\u0660": "0",
    "\u0661": "1",
    "\u0662": "2",
    "\u0663": "3",
    "\u0664": "4",
    "\u0665": "5",
    "\u0666": "6",
    "\u0667": "7",
    "\u0668": "8",
    "\u0669": "9",
  };

  return number
    .split("")
    .map((char) => indianToArabic[char] || char)
    .join("");
};

export const containsIndianNumerals = (number) => {
  const indianNumerals = [
    "\u0660",
    "\u0661",
    "\u0662",
    "\u0663",
    "\u0664",
    "\u0665",
    "\u0666",
    "\u0667",
    "\u0668",
    "\u0669",
  ];
  return number.split("").some((char) => indianNumerals.includes(char));
};

export const checkPasswordComplexity = (password) => {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasSpecial = /[!@#$%^&*]/.test(password);

  /*console.log(
    `password: ${password}, length: ${password.length}, minLength: ${minLength}, 
    hasUpperCase: ${hasUpperCase}, hasLowerCase: ${hasLowerCase}, 
    hasNumbers: ${hasNumbers}, hasSpecial: ${hasSpecial}`
  );*/

  return (
    password.length >= minLength &&
    hasUpperCase &&
    hasLowerCase &&
    hasNumbers &&
    hasSpecial
  );
};