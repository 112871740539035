import React, { useState, useRef, useEffect } from "react";
import TextField from "@mui/material/TextField";
import ModalInfo from "./modals/ModalInfo";
import ModalAlert from "./modals/ModalAlert";
import FileUploader from "../utilities/FileUploader";
import MyButton from "./MyButton";
import MySpinner from "./MySpinner";
import { useProductsContext } from "../Contexts/ProductsContext";
import addProduct_classes from "./addProduct.module.css";

import {
  checkArMessageValidity,
  checkArNameValidity,
  checkEnMessageValidity,
  checkEnNameValidity,
} from "../utilities/utilities";

import { addProduct } from "../api/api";

function AddProduct() {
  const { reFetchProducts } = useProductsContext();
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState("");
  const [modalInfoMessage, setModalInfoMessage] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [invalidArName, setInvalidArName] = useState(false);
  //const [invalidPrice, setInvalidPrice] = useState(false);
  const [isInvalidEnDescription, setIsInvalidEnDescription] = useState(false);
  const [isInvalidArDescription, setIsInvalidArDescription] = useState(false);
  const [enDesciptionLength, setEnDescriptionLength] = useState(0);
  const [arDesciptionLength, setArDescriptionLength] = useState(0);
  const [addProductResponse, setAddProductResponse] = useState(null);
  const [formData, setFormData] = useState({
    image: null,
    name: null,
    ar_name: null,
    description: null,
    ar_description: null,
  });

  const descriptionRef = useRef("");
  const arDescriptionRef = useRef("");
  const nameRef = useRef("");
  const arNameRef = useRef("");
  const imageRef = useRef(null);

  useEffect(() => {
    setEnDescriptionLength(
      formData.description ? formData.description.length : 0
    );
  }, [formData.description]);

  useEffect(() => {
    setArDescriptionLength(
      formData.ar_description ? formData.ar_description.length : 0
    );
  }, [formData.ar_description]);

  /*useEffect(() => {
    if (csrfTokenResponse) {
      console.log(`csrfTokenResponse: ${JSON.stringify(csrfTokenResponse)}.`);
      if (csrfTokenResponse.success) {
        console.log(`csrfToken is successful ==> send addItem request.`);
        addItemAPI(
          restaurantNameInContext,
          formData,
          csrfTokenResponse.csrfToken,
          setAddItemResponse
        );
      } else {
        console.error(`csrfTokenResponse is not successful.`);
        setShowModalAlert(true);
        setModalAlertMessage(
          `ErrorCode: ${csrfTokenResponse.errorCode}. Error message: ${
            csrfTokenResponse.errorMessage
              ? csrfTokenResponse.errorMessage
              : "undefined error."
          }`
        );
        setIsBusy(false);
      }
    }
  }, [csrfTokenResponse]);*/

  useEffect(() => {
    if (addProductResponse) {
      console.log(
        `We got a addProductResponse, success: ${addProductResponse.success}.`
      );
      if (!addProductResponse.success) {
        setShowModalAlert(true);
        setModalAlertMessage(
          `ErrorCode: ${
            addProductResponse.errorCode || "undefined"
          }. Error message: ${
            addProductResponse.errorMessage
              ? addProductResponse.errorMessage
              : "undefined error."
          }`
        );
      } else {
        setShowModalInfo(true);
        setModalInfoMessage(
          `Item (${addProductResponse.productName}) has been added.`
        );

        //reload products list
        reFetchProducts();
      }
      resetForm();
      setAddProductResponse(null);
      setIsBusy(false);
    }
  }, [addProductResponse]);

  const handleDescriptionChange = (e) => {
    if (checkEnMessageValidity(e.target.value) || e.target.value == "") {
      setIsInvalidEnDescription(false);
      setFormData((prevFormData) => {
        var newFormData = { ...prevFormData };
        newFormData.description = e.target.value;
        return newFormData;
      });
    } else {
      setIsInvalidEnDescription(true);
    }
  };

  const handleArDescriptionChange = (e) => {
    if (checkArMessageValidity(e.target.value) || e.target.value == "") {
      setIsInvalidArDescription(false);
      setFormData((prevFormData) => {
        var newFormData = { ...prevFormData };
        newFormData.ar_description = e.target.value;
        return newFormData;
      });
    } else {
      setIsInvalidArDescription(true);
    }
  };

  const handleNameInput = (e) => {
    //console.log(`English name: ${formData.enName}.`);
    //console.log(`Input text: ${e.target.value}.`);

    if (e.target.value == "" || checkEnNameValidity(e.target.value)) {
      setFormData((prevFormData) => {
        var newFormData = { ...prevFormData };
        newFormData.name = e.target.value;
        return newFormData;
      });
      setInvalidName(false);
    } else {
      console.log("invalid en name.");
      setInvalidName(true);
    }
  };

  const handleArNameInput = (e) => {
    //console.log(`English name: ${formData.enName}.`);
    //console.log(`Input text: ${e.target.value}.`);

    if (e.target.value == "" || checkArNameValidity(e.target.value)) {
      setFormData((prevFormData) => {
        var newFormData = { ...prevFormData };
        newFormData.ar_name = e.target.value;
        return newFormData;
      });
      setInvalidArName(false);
    } else {
      console.log("invalid ar name.");
      setInvalidArName(true);
    }
  };

  const handleImageChange = (image) => {
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      newFormData.image = image;
      return newFormData;
    });
  };

  const invalidImage = (msg) => {
    alert(msg);

    //reset image input
    //console.log("reset image input");

    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      newFormData.image = null;
      return newFormData;
    });
    imageRef.current.value = null;
  };

  const submitForm = (e) => {
    //console.log("formData:", { ...formData, image: null });
    e.preventDefault();

    //1- check all required fields are filled and are valid
    if (isInvalidArDescription || isInvalidEnDescription) {
      setModalAlertMessage("Invalid description.");
      setShowModalAlert(true);
      return;
    }

    //1- check all required fields are filled.
    console.log(`is formData.image: ${formData.image ? true : false}.`);
    console.log(`is formData.name: ${formData.name ? true : false}.`);
    console.log(`is formData.ar_name: ${formData.ar_name ? true : false}.`);
    console.log(
      `is formData.description: ${formData.description ? true : false}.`
    );
    console.log(
      `is formData.ar_description: ${formData.ar_description ? true : false}.`
    );
    if (
      formData.image &&
      formData.name &&
      formData.ar_name &&
      formData.description &&
      formData.ar_description
    ) {
      //setFormError(false);
      console.log(
        "all minimum required fields are filled, create data structure and send it to backend."
      );

      setIsBusy(true);
      setAddProductResponse(null);
      addProduct(formData, setAddProductResponse);
    } else {
      var errorString = "";
      if (!formData.image) errorString += "Image should be specified. \n";
      if (!formData.name) {
        errorString += "Name should be specified. \n";
        setInvalidName(true);
      }
      if (!formData.ar_name) {
        errorString += "Arabic name should be specified. \n";
        setInvalidArName(true);
      }
      if (!formData.description) {
        errorString += "Description of item is missing.\n";
        setIsInvalidEnDescription(true);
      }
      if (!formData.ar_description) {
        errorString += "Arabic description of item is missing.\n";
        setIsInvalidArDescription(true);
      }

      setModalAlertMessage(errorString ? errorString : "invalid form data.");
      setShowModalAlert(true);
    }
  };

  const resetForm = () => {
    console.log("reset form");
    setFormData((prevFormData) => {
      var newFormData = { ...prevFormData };
      newFormData.image = null;
      newFormData.name = null;
      newFormData.ar_name = null;
      newFormData.description = null;
      newFormData.ar_description = null;
      return newFormData;
    });
    descriptionRef.current.value = null;
    nameRef.current.value = null;
    arDescriptionRef.current.value = null;
    arNameRef.current.value = null;
    imageRef.current.value = null;
  };

  const closeModalAlert = () => {
    setShowModalAlert(false);
  };
  const closeModalInfo = () => {
    setShowModalInfo(false);
  };

  return (
    <div className={addProduct_classes.management_tab_container}>
      <div className={addProduct_classes.container}>
        <div className={addProduct_classes.input_container}>
          <div className={addProduct_classes.input_selection_div}>
            <div className={addProduct_classes.div_title}>
              Item's Primary Info
            </div>
            <div className={addProduct_classes.primary_info_div}>
              <div className={addProduct_classes.input_element}>
                <TextField
                  id="english-name-textfield"
                  rows={1}
                  type="text"
                  label="Item Name in English"
                  ref={nameRef}
                  helperText={invalidName && "Enter a valid English name."}
                  onChange={handleNameInput}
                  value={formData.name ? formData.name : ""}
                  error={invalidName}
                  required
                />
              </div>
              <div className={addProduct_classes.input_element}>
                <TextField
                  id="ar-name-textfield"
                  type="text"
                  label="Item Name in Arabic"
                  ref={arNameRef}
                  helperText={invalidArName && "ادخل اسم صحيح باللغة العربية"}
                  onChange={handleArNameInput}
                  value={formData.ar_name ? formData.ar_name : ""}
                  error={invalidArName}
                  required
                />
              </div>
            </div>
          </div>
          <div className={addProduct_classes.item_description_div}>
            <div className={addProduct_classes.div_title}>
              Item's Description
            </div>
            <div className={addProduct_classes.description_details}>
              <div className={addProduct_classes.input_element}>
                <TextField
                  id="english_description_textfield"
                  multiline
                  rows={6}
                  label={`Item Description in English ${
                    enDesciptionLength ? `(${enDesciptionLength}/200)` : ""
                  }`}
                  ref={descriptionRef}
                  onChange={handleDescriptionChange}
                  value={formData.description ? formData.description : ""}
                  error={isInvalidEnDescription}
                  required
                />
              </div>
              <div className={addProduct_classes.input_element}>
                <TextField
                  id="ar_description_textfield"
                  multiline
                  rows={6}
                  label={`Item Description in Arabic ${
                    arDesciptionLength ? `(${arDesciptionLength}/200)` : ""
                  }`}
                  ref={arDescriptionRef}
                  onChange={handleArDescriptionChange}
                  value={formData.ar_description ? formData.ar_description : ""}
                  error={isInvalidArDescription}
                  required
                />
              </div>
            </div>
          </div>
          <div className={addProduct_classes.image_display_div}>
            <div className={addProduct_classes.div_title}>Item's Image</div>
            <div className={addProduct_classes.input_element}>
              <div className={addProduct_classes.input_field}>
                <FileUploader
                  id="choose_image"
                  reference={imageRef}
                  buttonString={"Item Image"}
                  acceptType={"image/*"}
                  onFileSelectSuccess={(image) => handleImageChange(image)}
                  onFileSelectError={({ error }) => invalidImage(error)}
                />
              </div>
            </div>
            <div className={addProduct_classes.grid_main_image}>
              {formData.image ? (
                <>
                  <div className={addProduct_classes.input_label_description}>
                    <label htmlFor="item_image">Selected item's image:</label>
                  </div>
                  <img
                    id="item_image"
                    className={addProduct_classes.main_image}
                    src={formData.image}
                    alt="unable to show image"
                  />
                </>
              ) : (
                `no item image.`
              )}
            </div>
          </div>
          <div className={addProduct_classes.extras_selection_div}>
            <div className={addProduct_classes.div_title}>...</div>
            <div>Keep this div for preview/extra information</div>
          </div>
        </div>
        <div className={addProduct_classes.submit_reset_div}>
          <MyButton
            variant="primary"
            onClickHandler={submitForm}
            title="Submit"
          />
          <MyButton
            variant="secondary"
            onClickHandler={resetForm}
            title="Reset"
          />
        </div>
      </div>
      {showModalAlert && (
        <ModalAlert
          show={showModalAlert}
          alertMessage={modalAlertMessage}
          handleClose={closeModalAlert}
        />
      )}
      {showModalInfo && (
        <ModalInfo
          show={showModalInfo}
          infoMessage={modalInfoMessage}
          handleClose={closeModalInfo}
        />
      )}
      {isBusy && <MySpinner />}
    </div>
  );
}

export default AddProduct;
