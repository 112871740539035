import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuthenticationContext } from "../Contexts/AuthenticationContext";
import CryptoJS from "crypto-js";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { addUserAPI } from "../api/api";
import { checkPasswordComplexity } from "../utilities/utilities";
import pageClasses from "./page.module.css";
import "./login.css";

import MyButton from "../components/MyButton";
import MySpinner from "../components/MySpinner";

import ModalInfo from "../components/modals/ModalInfo";
import ModalAlert from "../components/modals/ModalAlert";

const UserRegisteration = () => {
  const { userRef } = useRef("");
  const navigate = useNavigate();

  const { authenticationStatus, updateAuthenticationStatus } =
    useAuthenticationContext();

  const [user, setUser] = useState("");
  const [kilma, setKilma] = useState("");
  const [rkilma, setRkilma] = useState("");
  const [skilma, setSkilma] = useState("");
  const [isInvalidKilma, setIsInvalidKilma] = useState(false);
  const [isInvalidRkilma, setIsInvalidRkilma] = useState(false);
  const [showKilma, setShowKilma] = useState(false);
  const [showRkilma, setShowRkilma] = useState(false);
  const [showSkilma, setShowSkilma] = useState(false);
  const [addUserResponse, setAddUserResponse] = useState(null);

  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState("An Error!");

  //const [showModalInfo, setShowModalInfo] = useState(false);
  //const [modalInfoMessage, setModalInfoMessage] = useState("Info Message!");
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    if (addUserResponse) {
      setIsBusy(false);
      console.log(
        `Got a new response for adding a new user: ${JSON.stringify(
          addUserResponse
        )}`
      );
      if (addUserResponse.success) {
        console.log("User is added succssfully !!!!!");
        let message = `User ${addUserResponse.username} is added successfully.`;
        setModalAlertMessage(message);
        setShowModalAlert(true);
      } else {
        console.error("Unable to add user.");
        let message = `Unable to add user ${
          addUserResponse.username
        }. Error message: ${addUserResponse.errorMessage || "undefined"}.`;
        setModalAlertMessage(message);
        setShowModalAlert(true);
      }
    }
  }, [addUserResponse]);

  const hashKilma = (kilma) => {
    const hash = CryptoJS.SHA256(kilma);
    return hash.toString(CryptoJS.enc.Hex);
  };

  const handleAddUser = async (e) => {
    e.preventDefault();

    let username = user.trim(); //remove whitespace before and after user (to make sure no duplicate user names)
    //check all fields are filled
    if (!user || !kilma || !rkilma || !skilma) {
      let message = `Please fill all the fields.`;
      setModalAlertMessage(message);
      setShowModalAlert(true);
      return;
    }

    //check that kilma and rkilma are the same
    if (kilma !== rkilma) {
      let message = `Passwords do not match.`;
      setModalAlertMessage(message);
      setShowModalAlert(true);
      return;
    }

    //check password complexity
    if (!checkPasswordComplexity(kilma)) {
      let message = `Password complexity is not met.`;
      setModalAlertMessage(message);
      setShowModalAlert(true);
      return;
    }

    try {
      const hashedKilma = hashKilma(kilma);
      const hashedSkilma = hashKilma(skilma);
      addUserAPI(username, hashedKilma, hashedSkilma, setAddUserResponse);
      setIsBusy(true);
      setUser("");
      setKilma("");
      setRkilma("");
      setSkilma("");
    } catch (error) {
      let message = `Error in hashing the kilma: (${error}).`;
      setModalAlertMessage(message);
      setShowModalAlert(true);
    }
  };

  const closeAlertModel = () => {
    setShowModalAlert(false);
  };

  return (
    <div className={pageClasses.pagebody}>
      <div>
        <div className="login_container">
          <h2>Add User</h2>
          <form className="login_form" onSubmit={handleAddUser}>
            <div className="login_element">
              <TextField
                type="text"
                id="username"
                className="input_element"
                label="User Name"
                autoComplete="off"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
              />
            </div>
            <div className="login_element">
              <TextField
                id="kilma"
                label="Password"
                rows={1}
                type={showKilma ? "text" : "password"}
                value={kilma ? kilma : ""}
                error={isInvalidKilma}
                onChange={(e) => setKilma(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div
                        onClick={() => {
                          setShowKilma(!showKilma);
                        }}
                      >
                        {showKilma ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </div>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </div>
            <div className="login_element">
              <TextField
                id="rkilma"
                label="Repeat Password"
                rows={1}
                type={showRkilma ? "text" : "password"}
                value={rkilma ? rkilma : ""}
                error={isInvalidRkilma}
                onChange={(e) => setRkilma(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div
                        onClick={() => {
                          setShowRkilma(!showRkilma);
                        }}
                      >
                        {showRkilma ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </div>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </div>

            <div className="login_element">
              <TextField
                id="skilma"
                label="Admin Password"
                rows={1}
                type={showSkilma ? "text" : "password"}
                value={skilma ? skilma : ""}
                //error={isInvalidRkilma}
                onChange={(e) => setSkilma(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div
                        onClick={() => {
                          setShowSkilma(!showSkilma);
                        }}
                      >
                        {showSkilma ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </div>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </div>
            <div className="button_div">
              <MyButton
                variant={"primary"}
                onClickHandler={handleAddUser}
                title={"Add User"}
              />
            </div>
          </form>
        </div>

        {showModalAlert && (
          <ModalAlert
            show={showModalAlert}
            alertMessage={modalAlertMessage}
            handleClose={closeAlertModel}
          />
        )}

        {isBusy && <MySpinner />}
      </div>
    </div>
  );
};

export default UserRegisteration;
