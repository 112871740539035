import React from "react";
import Spinner from "react-bootstrap/Spinner";
import i18n from "i18next";

import spinner_classes from "./spinner.module.css";
function MySpinner({message}) {
  return (
    <div className={spinner_classes.overlay}>
      <div className={spinner_classes.spinner} dir={i18n.dir}>
        <Spinner animation="border" variant="dark"/>
        {message || "Wait ..."}
      </div>
    </div>
  );
}

export default MySpinner;