import React, { useState, useEffect } from "react";
import { createContext, useContext } from "react";

import { fetchProducts } from "../api/api";

//create context (with 'descriptive' default values)
const ProductsContext = createContext({
  productsList: null,
  reFetchProducts: () => {},
});

export function useProductsContext() {
  return useContext(ProductsContext);
}

export function ProductsContextProvider({ children }) {
  const [fetchProductsResponse, setFetchProductsResponse] = useState(null);
  const [productsList, setProductsList] = useState(null);

  useEffect(() => {
    //fetch products when first loaded.
    fetchProducts(setFetchProductsResponse);
  }, []);

  //check if fetchProductsResponse has changed, if so, update productsList
  useEffect(() => {
    if (fetchProductsResponse) {
      console.log(`ProductsContextProvider: get a new fetchProductsResponse.`);
      if (fetchProductsResponse.success && fetchProductsResponse.products) {
        console.log("Products list is fetched successfully.");
        setProductsList(fetchProductsResponse.products);
      } else {
        console.log("Products list is NOT fetched successfully. Keep null");
        console.log(
          `Error message: ${fetchProductsResponse.errorMessage || "undefined."}`
        );
      }
    }
  }, [fetchProductsResponse]);

  //re-fetch products
  const reFetchProducts = () => {
    fetchProducts(setFetchProductsResponse);
  };

  return (
    <ProductsContext.Provider value={{ productsList, reFetchProducts }}>
      {children}
    </ProductsContext.Provider>
  );
}
