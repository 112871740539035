import React, { useState, useEffect } from "react";
import { createContext, useContext } from "react";
//import { fetchAuthenticationStatusAPI } from "../api/api.js";
import { useSessionStorage } from "../hooks/useSessionStorage";

//create empty context
const AuthenticationContext = createContext({});

const authStorage = "nakhalDatesAuth";

const USER_ROLES = {
  SUPERUSER: "admin",
  ADMIN: "user",
};

export function useAuthenticationContext() {
  return useContext(AuthenticationContext);
}

export function AuthenticationContextProvider({ children }) {
  

  const [authenticationStatus, setAuthenticationStatus] = useSessionStorage(
    authStorage,
    {
      isAuthenticated: false,
      username: null,
      role: null,
    }
  );

  //get authentication status after component is mounted
  //useEffect(() => {
  //  fetchAuthenticationStatusAPI(setAuthenticationStatus);
  //}, []);

  //console.log when authenticationStatus changes
  useEffect(() => {
    console.log(
      `"""""" Authentication status is changed: ${JSON.stringify(
        authenticationStatus
      )}`
    );
  }, [authenticationStatus]);

  

  const getAuthenticationStatus = () => {
    console.error("you sould get authentication status from backend.");
    //fetchAuthenticationStatusAPI(setAuthenticationStatus);
  };

  const updateAuthenticationStatus = (authStatus) => {
    setAuthenticationStatus((prev) => {
      return {
        isAuthenticated: authStatus.isAuthenticated
          ? authStatus.isAuthenticated
          : false,
        username: authStatus.username ? authStatus.username : prev.username,
        role: authStatus.role ? authStatus.role : prev.role,
      };
    });
  };

  const resetAuthentication = () => {
    setAuthenticationStatus({
      isAuthenticated: false,
      username: null,
      role: null,
    });
  };

  return (
    <AuthenticationContext.Provider
      value={{
        authenticationStatus,
        USER_ROLES,
        updateAuthenticationStatus,
        resetAuthentication,
        getAuthenticationStatus,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}