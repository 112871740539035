import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiMenu, mdiClose } from "@mdi/js";
import { LookContext } from "../Contexts/LookContext";
import { useAuthenticationContext } from "../Contexts/AuthenticationContext";
import { userLogoutAPI } from "../api/api";
import styles from "./navStyles.module.css";

import Logo from "./logo.jpg";

function NavBar() {
  const { authenticationStatus, resetAuthentication } =
    useAuthenticationContext();
  const [scrolled, setScrolled] = useState(false);
  const [menuClicked, setMenuClicked] = useState(false);
  const isMobile = useContext(LookContext);

  const navigate = useNavigate();

  const nav_strings = {
    home: "الرئيسية",
    add: "إضافة",
    remove: "إخراج",
    stats: "إحصائيات",
    generate: "ملصقات",
    products: "المنتجات",
    logout: "تسجيل خروج",
    login: "تسجيل دخول",
    registeration: "تسجيل مستخدم",
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 10) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, [window.scrollY]);

  useEffect(() => {
    console.log(`This is NavBar and isMobile is: ${isMobile}.`);
  }, [isMobile]);

  useEffect(() => {
    console.log(
      `Authentication Status: ${JSON.stringify(authenticationStatus)}`
    );
  }, [authenticationStatus]);

  useEffect(() => {
    console.log(`menuClicked: ${menuClicked}.`);
  }, [menuClicked]);

  let navbarClasses = scrolled
    ? `${styles.navbar} ${styles.scrolled}`
    : styles.navbar;

  const handleLogout = () => {
    // Clear local storage (remove all user-generated data)
    localStorage.clear();

    userLogoutAPI();
    resetAuthentication();
    navigate("/");
  };
  return (
    <div className={navbarClasses}>
      <div className={styles.navLogo}>
        <Link to="/">
          <img className={styles.navLogoImage} src={Logo} alt="Logo" />
        </Link>
      </div>
      {isMobile && (
        <div className={styles.mobileNavMenu}>
          {!menuClicked && (
            <button
              className={styles.burger}
              onClick={() => {
                setMenuClicked((prev) => {
                  return !prev;
                });
              }}
            >
              <Icon
                style={{ padding: 10 }}
                path={mdiMenu}
                title="Menu"
                size={"2rem"}
                color="blue"
              />
            </button>
          )}
          {menuClicked && (
            <button
              className={styles.closeIcon}
              onClick={() => {
                setMenuClicked((prev) => {
                  return !prev;
                });
              }}
            >
              <Icon
                style={{ padding: 10 }}
                path={mdiClose}
                title="Menu"
                size={"2rem"}
                color="blue"
              />
            </button>
          )}

          {menuClicked && (
            <nav>
              <ul className={styles.navItems}>
                <li
                  onClick={() => {
                    setMenuClicked(false);
                  }}
                >
                  <Link to="/">{nav_strings.home}</Link>
                </li>
                {authenticationStatus.isAuthenticated && (
                  <>
                    <li
                      onClick={() => {
                        setMenuClicked(false);
                      }}
                    >
                      <Link to="/add">{nav_strings.add}</Link>
                    </li>
                    <li
                      onClick={() => {
                        setMenuClicked(false);
                      }}
                    >
                      <Link to="/remove">{nav_strings.remove}</Link>
                    </li>
                    <li
                      onClick={() => {
                        setMenuClicked(false);
                      }}
                    >
                      <Link to="/stats">{nav_strings.stats}</Link>
                    </li>
                  </>
                )}
                {authenticationStatus.isAuthenticated &&
                  authenticationStatus.role === "admin" && (
                    <li
                      onClick={() => {
                        setMenuClicked(false);
                      }}
                    >
                      <Link to="/registeration">
                        {nav_strings.registeration}
                      </Link>
                    </li>
                  )}
                <li
                  onClick={() => {
                    setMenuClicked(false);
                  }}
                >
                  {authenticationStatus.isAuthenticated ? (
                    <Link to="/" onClick={handleLogout}>
                      {nav_strings.logout}
                    </Link>
                  ) : (
                    <Link to="/login">{nav_strings.login}</Link>
                  )}
                </li>
              </ul>
            </nav>
          )}
        </div>
      )}

      {!isMobile && (
        <nav className={styles.navSection}>
          <ul className={styles.navItems}>
            <li>
              <Link to="/">{nav_strings.home}</Link>
            </li>
            {authenticationStatus.isAuthenticated && (
              <>
                <li>
                  <Link to="/add">{nav_strings.add}</Link>
                </li>
                <li>
                  <Link to="/remove">{nav_strings.remove}</Link>
                </li>
                <li>
                  <Link to="/stats">{nav_strings.stats}</Link>
                </li>
                <li>
                  <Link to="/qrgenerator">{nav_strings.generate}</Link>
                </li>
                <li>
                  <Link to="/products">{nav_strings.products}</Link>
                </li>
              </>
            )}
            {authenticationStatus.isAuthenticated &&
              authenticationStatus.role === "admin" && (
                <li>
                  <Link to="/registeration">{nav_strings.registeration}</Link>
                </li>
              )}
            <li>
              {authenticationStatus.isAuthenticated ? (
                <Link to="/" onClick={handleLogout}>
                  {nav_strings.logout}
                </Link>
              ) : (
                <Link to="/login">{nav_strings.login}</Link>
              )}
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
}

export default NavBar;
