import React, { useState, useEffect, useContext } from "react";
//import Button from '@mui/material/Button';
//import Icon from "@mdi/react";
//import { Link } from "react-router-dom";
import StatsTable from "../components/StatsTable";
import HighLevelStats from "../components/HighLevelStats";
import ModalAlert from "../components/modals/ModalAlert";
import MySpinner from "../components/MySpinner";
import { LookContext } from "../Contexts/LookContext";
import { useAuthenticationContext } from "../Contexts/AuthenticationContext";
import { fetchDataStats } from "../api/api";
import classes from "./page.module.css";

function Stats() {
  const { resetAuthentication } = useAuthenticationContext();

  const [getStatsResponse, setGetStatsResponse] = useState(null);
  const [dataStats, setDataStats] = useState(null);
  const [displayStats, setDisplayStats] = useState(false);

  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState("An Error!");

  const [isBusy, setIsBusy] = useState(false);

  const isMobile = useContext(LookContext);

  useEffect(() => {
    if (getStatsResponse) {
      if (getStatsResponse.success) {
        setDataStats(getStatsResponse.stats);
      } else {
        console.log(
          `Error in getting stats: ${
            getStatsResponse.errorMessage || "undefined"
          }`
        );
        if (getStatsResponse.status === 400) {
          console.log(
            `Status is 400 in get-stats-response, reset authentication status.`
          );
          resetAuthentication();
        }
        setModalAlertMessage(getStatsResponse.errorMessage);
        setShowModalAlert(true);
      }
      setGetStatsResponse(null);
      setIsBusy(false);
    }
  }, [getStatsResponse]);

  useEffect(() => {
    fetchDataStats(setGetStatsResponse);
    setIsBusy(true);
  }, []);

  useEffect(() => {
    console.log("We got stats from the back end, try to display them.");
    if (dataStats) setDisplayStats(true);
  }, [dataStats]);

  useEffect(() => {
    console.log("---------------------------------------------");
    console.log(`This is Stats page, and isMobile has changed to: ${isMobile}`);
    console.log("---------------------------------------------");
  }, [isMobile]);

  return (
    <div className={classes.pagebody}>
      <h1 className={classes.pageTitle}>إحصائيات تمور نخل</h1>
      {displayStats && (
        <div className={classes.infoDiv}>
          <HighLevelStats stats={dataStats} />
          <StatsTable stats={dataStats} />
        </div>
      )}

      <ModalAlert
        show={showModalAlert}
        alertMessage={modalAlertMessage}
        handleClose={() => {
          setShowModalAlert(false);
        }}
      />

      {isBusy && <MySpinner />}
    </div>
  );
}

export default Stats;
