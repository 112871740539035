import React, { useRef } from "react";
import Compressor from "compressorjs";
import MyButton from "../components/MyButton";

function FileUploader({
  reference,
  buttonString,
  acceptType,
  onFileSelectError,
  onFileSelectSuccess,
}) {
  const handleFileInput = (event) => {
    const file = event.target.files[0];
    console.log(`file size: ${file.size}, and type: ${file.type}`);

    if (!file.type.startsWith("image/")) {
      onFileSelectError({
        error: "File should be image",
      });
    } else {
      const reader = new FileReader();

      reader.onload = () => {
        const dataURL = reader.result;
        onFileSelectSuccess(dataURL);
      };

      new Compressor(file, {
        quality: 0.24,
        maxWidth: 200, // Maximum output width
        maxHeight: 200, // Maximum output height
        success(compressedFile) {
          console.log(
            `compression is successful, and size is: ${compressedFile.size}`
          );
          if (compressedFile.size > 1024 * 1024) {
            console.log("compressed file exceeded 1MB, choose another file.");
            onFileSelectError({
              error: "compressed file exceeded 1MB, choose another file.",
            });
          } else {
            //onFileSelectSuccess(URL.createObjectURL(compressedFile));
            //onFileSelectSuccess(compressedFile);
            reader.readAsDataURL(compressedFile);
          }
        },
        error(err) {
          console.log(err.message);
          onFileSelectError({
            error: err.message,
          });
        },
      });

      //reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    reference.current.click();
  };

  return (
    <div className="file-uploader">
      <MyButton
        variant="primary"
        onClickHandler={handleButtonClick}
        title={buttonString ? buttonString : "select file"}
      />
      <input
        type="file"
        accept={acceptType ? acceptType : "*"}
        ref={reference}
        onChange={handleFileInput}
        style={{ display: "none" }}
      />
    </div>
  );
}

export default FileUploader;