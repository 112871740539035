import React, { useEffect, useState, useContext } from "react";
//import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import pageClasses from "./page.module.css";
import QRscanner from "../components/QRscanner";
import { parseQRtoElements } from "../tools/qroperations";
import { removeBox } from "../api/api";
import { LookContext } from "../Contexts/LookContext";
import MySpinner from "../components/MySpinner";
import ModalAlert from "../components/modals/ModalAlert";
import ModalInfo from "../components/modals/ModalInfo";

function RemoveBox() {
  const [qrString, setQrString] = useState("no string");
  const [scan, setScan] = useState(false);
  const [goodQR, setGoodQR] = useState(false);
  const [box, setBox] = useState({});
  const [removedSuccessfully, setRemovedSuccessfully] = useState({
    response: false,
    message: null,
    success: false,
  });
  const [newRemoveBoxResponse, setNewRemoveBoxResponse] = useState(null);

  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState("An Error!");
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [modalInfoMessage, setModalInfoMessage] = useState("Info Message!");
  const [isBusy, setIsBusy] = useState(false);

  const isMobile = useContext(LookContext);

  useEffect(() => {
    console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^");
    console.log(
      `This is Remove page, and isMobile has changed to: ${isMobile}`
    );
    console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^");
  }, [isMobile]);

  useEffect(() => {
    if (newRemoveBoxResponse) {
      console.log(
        `Got a response for removing a box: ${JSON.stringify(
          newRemoveBoxResponse
        )}`
      );
      if (newRemoveBoxResponse.success) {
        console.log("Box is removed succssfully !!!!!");
      } else {
        console.log(
          `Unable to add box with SN: ${newRemoveBoxResponse.serialNumber}, message: ${newRemoveBoxResponse.message}.`
        );
      }

      setRemovedSuccessfully({
        response: true,
        message: newRemoveBoxResponse.message,
        success: newRemoveBoxResponse.success,
      });
      setIsBusy(false);
      //reset newAddBoxResponse
      setNewRemoveBoxResponse(null);
    }
  }, [newRemoveBoxResponse]);

  useEffect(() => {
    console.log(`scan: ${scan}, goodQR: ${goodQR}.`);
  }, []);

  const handleReadString = (Readqrstring) => {
    console.log(`read qrstring is: ${Readqrstring}.`);
    setQrString(Readqrstring);
    const box = parseQRtoElements(Readqrstring);
    if (box) {
      setBox(box);
      setGoodQR(true);
      setScan(false);
    } else {
      setGoodQR(false);
      console.log("invalid qr string.");
    }
  };

  const handleScanButton = () => {
    setScan(true);
    setRemovedSuccessfully({ response: false, message: null, success: false });
  };

  const handleRemovingBox = () => {
    console.log(`This is handleRemovingBox: ${JSON.stringify(box)}.`);

    if (box) {
      try {
        removeBox(box, setNewRemoveBoxResponse);
      } catch (error) {
        console.log(`Error in adding box: ${error}.`);
      }
    }
    setGoodQR(false);
    setScan(false);
    setQrString("no string");
  };

  const displayError = (error) => {
    console.log(`Problem in reading QR code: ${error}`);
  };

  return (
    <div className={pageClasses.pagebody}>
      <h1 className={pageClasses.pageTitle}>إخراج صندوق من المخزن</h1>
      {!scan && (
        <button
          className={pageClasses.button}
          variant="container"
          onClick={handleScanButton}
        >
          Press To Scan QR
        </button>
      )}
      {goodQR && (
        <button
          className={pageClasses.button}
          variant="contained"
          onClick={handleRemovingBox}
        >
          Press To Remove Box
        </button>
      )}
      <h2 className={pageClasses.qrString}>
        {" "}
        {`QR String for box: ${qrString}`}
      </h2>
      {scan && (
        <QRscanner
          className={pageClasses.scanner}
          scanHandler={handleReadString}
          errorHandler={displayError}
        />
      )}

      {removedSuccessfully.response &&
        (removedSuccessfully.success ? (
          <Alert severity="success">Box is removed successfully.</Alert>
        ) : (
          <Alert severity="error">{removedSuccessfully.message}</Alert>
        ))}

      <ModalAlert
        show={showModalAlert}
        alertMessage={modalAlertMessage}
        handleClose={() => {
          setShowModalAlert(false);
        }}
      />

      <ModalInfo
        show={showModalInfo}
        infoMessage={modalInfoMessage}
        handleClose={() => {
          setShowModalInfo(false);
        }}
      />

      {isBusy && <MySpinner />}
    </div>
  );
}

export default RemoveBox;
