import React, { useState, useEffect } from "react";
//import InputLabel from "@mui/material/InputLabel";
//import MenuItem from "@mui/material/MenuItem";
//import FormHelperText from "@mui/material/FormHelperText";
//import FormControl from "@mui/material/FormControl";
//import Select from "@mui/material/Select";

import styles from "./selectLabels.module.css";

import {
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
} from '@mui/material';

export default function SelectLabels({ options, givenLabel, listLabel, handler }) {
  const [selectedOption, setSelectedOption] = useState(options[0].value);

  useEffect(() => {
    handler(options[0].value);
  }, []);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    //console.log(`Selected option: ${event.target.value}`);
    handler(event.target.value);
  };

  return (
    <FormControl className={styles.formControl} variant="outlined" sx={{ m: 1, minWidth: 160 }}>
      <InputLabel className={styles.inputLabel}>{givenLabel}</InputLabel>
      <Select className={styles.selectedItem} value={selectedOption} label={givenLabel} onChange={handleChange}>
        {/*<MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={10}>Ten</MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem>*/}

        {options.map((o) => (
          <MenuItem className={styles.menuItem} key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
      {/*<FormHelperText>{listLabel}</FormHelperText>*/}
    </FormControl>
  );
}
