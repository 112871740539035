import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import { AuthenticationContextProvider } from "./Contexts/AuthenticationContext";
import { ProductsContextProvider } from "./Contexts/ProductsContext";

// replace console.* for disable log on production
if (process.env.REACT_APP_MODE === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

// Create a root and render the App component
const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <AuthenticationContextProvider>
      <ProductsContextProvider>
        <App />
      </ProductsContextProvider>
    </AuthenticationContextProvider>
  </React.StrictMode>
);
