import React, { useEffect, useState, useContext } from "react";
//import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import pageClasses from "./page.module.css";
import QRscanner from "../components/QRscanner";
import { parseQRtoElements } from "../tools/qroperations";
import { enquireBox } from "../api/api";
import { LookContext } from "../Contexts/LookContext";

function QRscannerpage() {
  const [qrString, setQrString] = useState("no string");
  const [scan, setScan] = useState(false);
  const [goodQR, setGoodQR] = useState(false);
  const [enquiredSuccessfully, setEnquiredSuccessfully] = useState({
    response: false,
    message: null,
    success: false,
  });
  const [box, setBox] = useState({});
  const [enquiryResponse, setEnquiryResponse] = useState(null);

  const isMobile = useContext(LookContext);

  useEffect(() => {
    console.log("++++++++++++++++++++++++++++++++++++++++++++++");
    console.log(
      `This is enquiry page, and isMobile has changed to: ${isMobile}`
    );
    console.log("++++++++++++++++++++++++++++++++++++++++++++++");
  }, [isMobile]);

  useEffect(() => {
    if (enquiryResponse) {
      console.log(`Got a response for box: ${JSON.stringify(enquiryResponse)}`);
      if (enquiryResponse.success) {
        console.log(
          `Enquiry is successfull, available: ${enquiryResponse.available}`
        );
      } else {
        console.log(
          `Unable to enquire box with SN: ${enquiryResponse.serialNumber}, message: ${enquiryResponse.message}.`
        );
      }

      setEnquiredSuccessfully({
        response: true,
        message: enquiryResponse,
        success: enquiryResponse.success,
      });

      //reset enquiry response
      setEnquiryResponse(null);
    }
  }, [enquiryResponse]);

  useEffect(() => {
    console.log(`scan: ${scan}, goodQR: ${goodQR}.`);
  }, []);

  const displayError = (error) => {
    console.log(`Problem in reading qr string: ${error}`);
  };

  const handleReadString = (Readqrstring) => {
    console.log(`read qrstring is: ${Readqrstring}.`);
    setQrString(Readqrstring);
    const box = parseQRtoElements(Readqrstring);
    if (box) {
      setBox(box);
      setGoodQR(true);
      setScan(false);
    } else {
      setGoodQR(false);
      console.log("invalid qr string.");
    }
  };

  const handleScanButton = () => {
    setScan(true);
    setEnquiredSuccessfully({ response: false, message: null, success: false });
  };

  const handleEnquireScan = () => {
    console.log(`This is handleEnquireScan: ${JSON.stringify(box)}.`);

    if (box) {
      try {
        enquireBox(box, setEnquiryResponse);
      } catch (error) {
        console.log(`Error in enquiring box: ${error}.`);
      }
    }
    setGoodQR(false);
    setScan(false);
    setQrString("no string");
  };

  return (
    <div className={pageClasses.pagebody}>
      <h1 className={pageClasses.pageTitle}>مسح الملصق</h1>
      {!scan && (
        <button
          className={pageClasses.button}
          variant="contained"
          onClick={handleScanButton}
        >
          إضغط للمسح
        </button>
      )}
      {goodQR && (
        <button
          className={pageClasses.button}
          variant="contained"
          onClick={handleEnquireScan}
        >
          إضغط للاستعلام
        </button>
      )}
      <h2 className={pageClasses.qrString}> {`QR String for box: ${qrString}`}</h2>
      {scan && (
        <QRscanner
          className={pageClasses.scanner}
          scanHandler={handleReadString}
          errorHandler={displayError}
        />
      )}
      {enquiredSuccessfully.response &&
        (enquiredSuccessfully.success ? (
          <Alert className={pageClasses.alert} severity="success">
            {enquiredSuccessfully.message.available ? (
            JSON.stringify(enquiredSuccessfully.message)
            ):( `هذا الصندوق ${enquiredSuccessfully.message.serialNumber} غير موجود` )}
          </Alert>
        ) : (
          <Alert className={pageClasses.alert} severity="error">
            {JSON.stringify(enquiredSuccessfully.message)}
          </Alert>
        ))}
    </div>
  );
}

export default QRscannerpage;
