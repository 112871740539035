import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import QRcode from "qrcode.react";

function QRsTable({ data }) {
  useEffect(() => {
    console.log(`QRsTable: data: ${JSON.stringify(data)}.`);
  }, [data]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="right">QR String</TableCell>
            <TableCell align="right">QR Image</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell align="right">{row.qrString}</TableCell>
              <TableCell align="right">
                <QRcode data-qr={row.id} value={row.qrString} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default QRsTable;
