import React, { useEffect } from "react";
//import ReactDOM from "react-dom";
//import Button from "@mui/material/Button";
//import DataTable from "react-data-table-component";
import QRcode from "qrcode.react";
import jsPDF from "jspdf";
import classes from "../pages/page.module.css";
import QRsTable from "./QRsTable";

const QRList = ({ qrStrings }) => {
  //console.log(`QRList: baseSN: ${baseSN}, and baseQRString: ${baseQRString}.`);
  /*let data = [];
  const num_of_qrs = 20;
  //
  useEffect(() => {
    for (let i = 0; i < num_of_qrs; i++)
      data[i] = {
        id: parseInt(baseSN) + i,
        qrString:
          prefix + "-" + (parseInt(baseSN) + i).toString() + "-" + baseQRString,
      };

    console.log(`The generated QR strings: ${JSON.stringify(data)}.`);
  }, [prefix, baseSN, baseQRString]);*/

  useEffect(() => {
    console.log(`QRList: qrStrings: ${JSON.stringify(qrStrings)}.`);
  },[qrStrings]);




  const generateDoc = () => {
    console.log(`This generateDoc, qrStrings length is: ${qrStrings.length}.`);
    var doc = new jsPDF("p", "pt", "c6");
    doc.setFontSize(16);
    doc.text(140, 30, "QR List");
    doc.setFontSize(8);
    doc.line(40, 35, 280, 35, "S");
    let qrCodeCanvas;
    let qrCodeDataUri;

    for (let i = 0; i < qrStrings.length; i++) {
      var selectorString = "[qrStrings-qr='" + qrStrings[i].id + "']";
      console.log(`selectorString: ${selectorString}.`);
      qrCodeCanvas = document.querySelectorAll(
        "[qrStrings-qr='" + qrStrings[i].id + "']"
      )[0];
      qrCodeDataUri = qrCodeCanvas.toDataURL("image/png");

      doc.text(60, 60 + i * 40, qrStrings[i].qrString);
      doc.addImage(qrCodeDataUri, "JPEG", 230, 40 + i * 40, 30, 30);
      doc.line(40, 40 + i * 40 + 35, 280, 40 + i * 40 + 35, "S");
    }
    doc.save("qrlist.pdf");
  };

  const generateDoc_compact = () => {
    console.log(`This generateDoc_compact, qrStrings length is: ${qrStrings.length}.`);
    var doc = new jsPDF("p", "pt", "c6");
    doc.setFontSize(16);
    doc.text(140, 30, "QR List");
    doc.setFontSize(6);
    doc.setTextColor(0, 0, 139);
    doc.line(40, 35, 280, 35, "S");
    let qrCodeCanvas;
    let qrCodeDataUri;

    for (let i = 0; i < qrStrings.length; i++) {
      var selectorString = "[data-qr='" + qrStrings[i].id + "']";
      console.log(`selectorString: ${selectorString}.`);
      qrCodeCanvas = document.querySelectorAll(
        "[data-qr='" + qrStrings[i].id + "']"
      )[0];
      qrCodeDataUri = qrCodeCanvas.toDataURL("image/png");

      if (i < 10) {
        doc.addImage(qrCodeDataUri, "JPEG", 85, 45 + i * 40, 23, 23);
        doc.text(40, 75 + i * 40, qrStrings[i].qrString);
        //doc.line(40, 40 + i * 40 + 35, 280, 40 + i * 40 + 35, "S");
      } else {
        doc.addImage(qrCodeDataUri, "JPEG", 215, 45 + (i - 10) * 40, 23, 23);
        doc.text(170, 75 + (i - 10) * 40, qrStrings[i].qrString);
      }
    }
    doc.save("qrlist.pdf");
  };

  /*return (
    <div id="qrlist">
      <QRcode
        id={baseSN}
        value={prefix + "-" + baseSN + "-" + baseQRString}
        size={320}
        includeMargin={true}
      />
    </div>
  );
  */

  //render() {
  const columns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
    },
    {
      name: "QR String",
      selector: "qrString",
      sortable: true,
      right: true,
    },
    {
      name: "Qr code",
      selector: "qrcode",
      ignoreRowClick: true,
      cell: (row) =>
        row.id ? <QRcode data-qr={row.id} value={row.qrString} /> : "",
    },
  ];
  return (
    <div className={classes.qrList}>
      <button className={classes.button} onClick={generateDoc_compact}>
        تنزيل الملف
      </button>

      {/*<DataTable title="قائمة الملصقات" columns={columns} data={data} />*/}
      {qrStrings && qrStrings.length > 0 && <QRsTable data={qrStrings} />}
    </div>
  );
  //}
};

export default QRList;
