import React, { useEffect, useState } from "react";
//import Button from "react-bootstrap/Button";
import "./myButton.css";

function MyButton({
  variant,
  onClickHandler,
  title,
  flashCondition,
  smallButton,
  disabled,
}) {
  const variants = { primary: "primary", secondary: "secondary" };
  const [flash, setFlash] = useState();
  const [small, setSmall] = useState();

  useEffect(() => {
    setFlash(flashCondition ? true : false);
  }, [flashCondition]);

  useEffect(() => {
    setSmall(smallButton ? true : false);
  }, [smallButton]);

  const determineVariant = (variant) => {
    if (!variant || !variant in variants) return variants.primary;
    return variant;
  };

  return (
    <button
      variant={variant}
      onClick={disabled ? null : onClickHandler}
      className={`myButton ${determineVariant(variant)} ${
        flash ? "flashing" : ""
      } ${small ? "smallerButton" : ""}`}
      disabled={disabled || false}
    >
      {title}
    </button>
  );
}

export default MyButton;