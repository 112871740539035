import React, { useEffect, useState, useContext } from "react";
//import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import pageClasses from "./page.module.css";
import QRscanner from "../components/QRscanner";
import { parseQRtoElements } from "../tools/qroperations";
import { addBox } from "../api/api";
import { LookContext } from "../Contexts/LookContext";
import MySpinner from "../components/MySpinner";
import ModalAlert from "../components/modals/ModalAlert";
import ModalInfo from "../components/modals/ModalInfo";

function AddBox() {
  const [qrString, setQrString] = useState("no string");
  const [scan, setScan] = useState(false);
  const [goodQR, setGoodQR] = useState(false);
  const [addedSuccessfully, setAddedSuccessfully] = useState({
    response: false,
    message: null,
    success: false,
  });
  const [box, setBox] = useState({});
  const [newAddBoxResponse, setNewAddBoxResponse] = useState(null);

  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState("An Error!");
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [modalInfoMessage, setModalInfoMessage] = useState("Info Message!");
  const [isBusy, setIsBusy] = useState(false);

  const isMobile = useContext(LookContext);

  useEffect(() => {
    console.log("++++++++++++++++++++++++++++++++++++++++++++++");
    console.log(`This is Add page, and isMobile has changed to: ${isMobile}`);
    console.log("++++++++++++++++++++++++++++++++++++++++++++++");
  }, [isMobile]);

  useEffect(() => {
    if (newAddBoxResponse) {
      console.log(
        `Got a response for adding a box: ${JSON.stringify(newAddBoxResponse)}`
      );
      if (newAddBoxResponse.success) {
        console.log("Box is added succssfully !!!!!");
      } else {
        console.log(
          `Unable to add box with SN: ${newAddBoxResponse.serialNumber}, message: ${newAddBoxResponse.message}.`
        );
      }

      setAddedSuccessfully({
        response: true,
        message: newAddBoxResponse.message,
        success: newAddBoxResponse.success,
      });
      setIsBusy(false);
      setNewAddBoxResponse(null);

    }
  }, [newAddBoxResponse]);

  useEffect(() => {
    console.log(`scan: ${scan}, goodQR: ${goodQR}.`);
  }, []);

  const displayError = (error) => {
    console.log(`Problem in reading qr string: ${error}`);
  };

  const handleReadString = (Readqrstring) => {
    console.log(`read qrstring is: ${Readqrstring}.`);
    setQrString(Readqrstring);
    const box = parseQRtoElements(Readqrstring);
    if (box) {
      setBox(box);
      setGoodQR(true);
      setScan(false);
    } else {
      setGoodQR(false);
      console.log("invalid qr string.");
    }
  };

  const handleScanButton = () => {
    setScan(true);
    setAddedSuccessfully({ response: false, message: null, success: false });
  };

  const handleAddingBox = () => {
    console.log(`This is handleAddingBox: ${JSON.stringify(box)}.`);

    if (box) {
      try {
        addBox(box, setNewAddBoxResponse);
        setIsBusy(true);
      } catch (error) {
        console.log(`Error in adding box: ${error}.`);
      }
    }
    setGoodQR(false);
    setScan(false);
    setQrString("no string");
  };

  return (
    <div className={pageClasses.pagebody}>
      <h1 className={pageClasses.pageTitle}>إضافة صندوق جديد</h1>
      {!scan && (
        <button
          className={pageClasses.button}
          variant="contained"
          onClick={handleScanButton}
        >
          Press To Scan QR
        </button>
      )}
      {goodQR && (
        <button
          className={pageClasses.button}
          variant="contained"
          onClick={handleAddingBox}
        >
          Press To Add Box
        </button>
      )}
      <h2 className={pageClasses.qrString}>
        {" "}
        {`QR String for box: ${qrString}`}
      </h2>
      {scan && (
        <QRscanner
          className={pageClasses.scanner}
          scanHandler={handleReadString}
          errorHandler={displayError}
        />
      )}
      {addedSuccessfully.response &&
        (addedSuccessfully.success ? (
          <Alert severity="success">Box is added successfully.</Alert>
        ) : (
          <Alert severity="error">{addedSuccessfully.message}</Alert>
        ))}

      <ModalAlert
        show={showModalAlert}
        alertMessage={modalAlertMessage}
        handleClose={() => {
          setShowModalAlert(false);
        }}
      />

      <ModalInfo
        show={showModalInfo}
        infoMessage={modalInfoMessage}
        handleClose={() => {
          setShowModalInfo(false);
        }}
      />

      {isBusy && <MySpinner />}
    </div>
  );
}

export default AddBox;
